import React from "react";
import getTheme from "../../themeUtil";
const theme = getTheme();

function TimelineItem(props) {
  return (
    <div className={`timeline-item ${theme.timeLine}`}>
      {/* <div className="timeline-marker is-image is-32x32">
        <i className="fa fa-flag"></i>
      </div> */}
      <div className="timeline-marker is-icon">
        <i className="fa fa-flag"></i>
      </div>
      <div className={`timeline-content ${theme.text1}`}>
        <p className="heading">{props.date}</p>
        <h1 className={`title is-4 ${theme.text1} `}>{props.company}</h1>
        <p style={{ maxWidth: "25em" }}>{props.summary}</p>
      </div>
    </div>
  );
}

export default TimelineItem;
