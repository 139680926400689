import React from "react";
import Badge from "../elements/Badge";
import Resume from "../../resume.json";
import getTheme from "../../themeUtil";

const theme = getTheme();

function AboutMe() {
  return (
    <section className={`section ${theme.backgroundClass}`} id="aboutMe">
      <div className="container has-text-centered">
        <figure className="image container is-180x180">
          <img
            width="180"
            height="180"
            src={Resume.basics.picture}
            alt={Resume.basics.name}
            className="is-rounded"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = Resume.basics.x_pictureFallback;
            }}
          />
        </figure>
        <p className={`subtitle is-4 ${theme.text1} has-text-weight-bold`}>
          {Resume.basics.x_title}
        </p>
        <p className={`subtitle is-5 ${theme.text1} has-text-weight-light summary-text`}>
          {Resume.basics.summary}
        </p>
        <div className="container interests">
          <div className="field is-grouped is-grouped-multiline has-text-centered">
            {Resume.interests.map((value, index) => {
              return (
                <Badge key={index} text={value.name} faIcon={value.x_icon} />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
